import { Button, Input, Modal } from "antd"
import { useEffect } from "react"
import { QrcodeType } from "../../types/types"
import { toast } from "react-toastify"
import copy from "copy-to-clipboard"

type ModalPixProps = {
    handleOk: () => void
    setIsModalOpen: (value: boolean) => void
    isModalOpen: boolean
    qrcode?: QrcodeType
}

export function ModalPix(props: ModalPixProps) {
    async function handleCopiaLink() {
        if (props.qrcode) {
            copy(props.qrcode?.code)
            toast.success('Código copiado com sucesso!')
        } else {
            toast.error('Não foi possível copiar o código!')
        }
    }

    return (
        <Modal title="Leia o QRCode ou copie o link de pagamento" open={props.isModalOpen} onCancel={props.handleOk} footer={null}>
            <div className='pix-detail'>
                <img src={props.qrcode?.img} alt='QR Code do PIX' />
                <Input placeholder="Chave PIX" value={props.qrcode?.code}/>
                <Button type="primary" onClick={handleCopiaLink}>Copiar Código PIX</Button>
            </div>
        </Modal>
    )
}