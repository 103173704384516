import '../styles/invoice.scss'
import { Main } from "../components/layout/Main"
import { BreadcrumbType, InvoiceType, QrcodeType } from "../types/types"
import { Button, List, Space, Typography } from 'antd'
import { AccountBookOutlined } from '@ant-design/icons'
import { useEffect, useState } from 'react'
import api from '../services/api'
import { ModalPix } from '../components/objects/ModalPix'
import { toast } from 'react-toastify'
import { ModalPayment } from '../components/objects/ModalPayment'
import dayjs from 'dayjs'


const items: BreadcrumbType[] = [
    {
        title: 'Home'
    },
    {
        title: 'Mensalidades',
    },
]

export function Invoice() {
    const [data, setData] = useState<InvoiceType[]>([])

    const [isModalPaymentOpen, setIsModalPaymentOpen] = useState(false)
    const [selectedInvoice, setSelectedInvoice] = useState<InvoiceType>()

    const [isModalPixOpen, setIsModalPixOpen] = useState(false)
    const [qrcode, setQrcode] = useState<QrcodeType>({
        code: '',
        img: 'https://t4.ftcdn.net/jpg/04/75/01/23/360_F_475012363_aNqXx8CrsoTfJP5KCf1rERd6G50K0hXw.jpg'
    })

    const handleClick = async (item: InvoiceType) => {
        if (item.status === "Pago") {
            setSelectedInvoice(item)
            setIsModalPaymentOpen(true)
        } else {
            await api.post('pix', new URLSearchParams({
                invoice: item.id,
            }))
                .then((response) => {
                    if (response.data) {
                        const updatedQrcode = { ...qrcode }
                        updatedQrcode.img = response.data.qr_code_url
                        updatedQrcode.code = response.data.qr_code
                        setQrcode(updatedQrcode)
                        setIsModalPixOpen(true)

                    } else {
                        toast.error("error:" + response.data)
                    }
                })
                .catch((e) => {
                    if (e.response) {
                        // Is this the correct way?
                        toast.error(e.response?.data.error)
                    }
                })
        }

    }

    const handlePixOk = () => {
        setIsModalPixOpen(false)
    }

    const handlePaymentOk = () => {
        setIsModalPaymentOpen(false)
    }

    useEffect(() => {
        async function loadData() {
            try {
                const response = await api.get('invoice/me')
                setData(response.data)
            } catch (error) {
                console.error('Erro ao carregar dados do usuário:', error);
            }
        }
        loadData()
    }, [])
    return (
        <Main itemBreadcrumb={items}>
            <ModalPix handleOk={handlePixOk} isModalOpen={isModalPixOpen} setIsModalOpen={setIsModalPixOpen} qrcode={qrcode} />
            <ModalPayment handleOk={handlePaymentOk} isModalOpen={isModalPaymentOpen} setIsModalOpen={setIsModalPaymentOpen} invoice={selectedInvoice} />
            <div className='content-invoice'>
                <Space direction="vertical" >
                    <Typography.Title level={4}>
                        Mensalidades
                    </Typography.Title>
                </Space>
                <List
                    itemLayout="horizontal"
                    dataSource={data}
                    renderItem={(item, index) => (
                        <List.Item
                            actions={[
                                <Button type="primary" htmlType="submit" className="invoice-form-button" onClick={() => handleClick(item)}>
                                    {item.status === "Pago" ? "Comprovante" : "Pagar"}
                                </Button>
                            ]}>
                            <List.Item.Meta
                                avatar={<AccountBookOutlined />}
                                title={item.description}
                                description={parseFloat(item.value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) + " - " + dayjs(item.due_at, "YYYY-MM-DD HH:mm:ss").format('DD/MM/YYYY') + " - " + item.status}
                            />
                        </List.Item>
                    )}
                />
            </div>
        </Main>
    )
}