import '../../styles/mainlogout.scss'

import { Layout, theme } from 'antd'
import { Content, Footer, Header } from 'antd/es/layout/layout'
import { ReactNode } from 'react'

type MainLogoutProps = {
    children?: ReactNode
}


export function MainLogout(props: MainLogoutProps) {
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken()
    return (
        <Layout style={{ minHeight: '100dvh' }}>
            <Layout>
                <Content className='login-content' style={{ margin: '16px', height: '100%' }}>
                    <div className='login-content-div'
                        style={{
                            padding: 26,
                            background: colorBgContainer,
                            borderRadius: borderRadiusLG,
                        }}
                    >
                        {props.children}
                    </div>
                </Content>
                <Footer style={{ textAlign: 'center', padding: '5px' }}>Associação Amigos da Pimpa ©2024 Created by Rodrigo Balaba</Footer>
            </Layout>

        </Layout>

    )
}

