import { useState, useEffect } from 'react'
import api from '../services/api'

interface AuthState {
  isAuthenticated: boolean
  loading: boolean
  error: string | null
}

export function useIsAuth(): AuthState {
  const [authState, setAuthState] = useState<AuthState>({
    isAuthenticated: false,
    loading: true,
    error: null
  })

  useEffect(() => {
    const token = localStorage.getItem('token')

    if (!token) {
      setAuthState({
        isAuthenticated: false,
        loading: false,
        error: 'No token found'
      })
      return
    }

    const validateToken = async () => {
      try {
        const response = await api.get('validaToken/token/'+localStorage.getItem("token"))
        if (response.data.Authorization) {
          setAuthState({
            isAuthenticated: true,
            loading: false,
            error: null
          })
        } else {
          setAuthState({
            isAuthenticated: false,
            loading: false,
            error: 'Invalid token'
          })
        }
      } catch (error) {
        setAuthState({
          isAuthenticated: false,
          loading: false,
          error: 'Error validating token'
        })
      }
    }

    validateToken()
  }, [])

  return authState
}
