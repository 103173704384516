import '../styles/notfound.scss'
import { Button, List, Space, Table, TableColumnsType , Typography } from "antd"
import { useNavigate } from "react-router-dom"
import { Main } from '../components/layout/Main'
import { BreadcrumbType, TeamType } from '../types/types'
import { useEffect, useState } from 'react'
import { WhatsAppOutlined } from '@ant-design/icons'
import api from '../services/api'

const items: BreadcrumbType[] = [
    {
        title: 'Home'
    },
    {
        title: 'Meu Time',
    },
]
export function Team() {
    function changeAction(item:string){
        data.forEach(element => {
            if(element.key===item){
                const message = 'Olá Jeff, eu preciso trocar o jogador '+element.name +'('+element.nickname+') da posição: '+element.field
                const url = `https://api.whatsapp.com/send?phone=+5547988255389&text=${encodeURIComponent(message)}`
                window.open(url, '_blank')
            }
        })
    }
    const [data, setData] = useState<TeamType[]>([])
    const [isLeader, setIsLeader] = useState(false)
    useEffect(() => {
        async function loadData() {
            try {
                const response = await api.get('team/me')
                setData(response.data.list)
                setIsLeader(response.data.is_leader)
            } catch (error) {
                console.error('Erro ao carregar dados do usuário:', error);
            }
        }
        loadData()
    }, [])
    const columns: TableColumnsType <TeamType> = [
        {
            title: 'Posição',
            dataIndex: 'field',
            key: 'field',
            
        },
        {
            title: 'Apelido',
            dataIndex: 'nickname',
            key: 'nickname',
        },
        {
            title: 'Nome',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Whatsapp',
            dataIndex: 'phone',
            key: 'phone',
            render: (_, record) => (
                <a href={'https://api.whatsapp.com/send/?phone=55' + record.phone.replace(/[\(\)\-]/g, '')} target='_blank' rel='noreferrer'><WhatsAppOutlined /></a>
            )
        },
        {
            title: 'Financeiro',
            dataIndex: 'financial',
            key: 'financial',
            width: '150px',
            hidden: isLeader ? false : true,
        },
        {
            title: 'Ações',
            dataIndex: 'actions',
            key: 'actions',
            width: '150px',
            hidden: isLeader ? false : true,
            render: (_, record) => (
                <Button onClick={()=>changeAction(record.key)}>Solicitar troca</Button>
            )
        },
    ]
    return (
        <Main itemBreadcrumb={items}>
            <div className='content-invoice'>
                <Space direction="vertical" >
                    <Typography.Title level={4}>
                        Meu Time
                    </Typography.Title>
                </Space>
                <Table dataSource={data} columns={columns} pagination={{pageSize:11, hideOnSinglePage:true}} style={{overflow:'auto'}} scroll={{ x: 'max-content' }}/>
            </div>
        </Main>
    )
}