import '../styles/home.scss'
import { Main } from "../components/layout/Main"
import { BreadcrumbType, PostType } from "../types/types"
import { Button, List, Modal, Typography } from 'antd'
import { useEffect, useState } from 'react'
import api from '../services/api'
import { NotificationOutlined, SyncOutlined, DollarOutlined } from '@ant-design/icons'

const items: BreadcrumbType[] = [
    {
        title: 'Home'
    },
]

export function Home() {
    const [data, setData] = useState<PostType[]>([])

    useEffect(() => {
        async function loadData() {
            try {
                const response = await api.get('post/list')
                setData(response.data)
            } catch (error) {
                console.error('Erro ao carregar dados do usuário:', error);
            }
        }
        loadData()
    }, [])

    return (
        <Main itemBreadcrumb={items}>
            <div className='content-home-button'>
                <Button type="primary" key="ranking" onClick={()=> {window.open('https://copafacil.com/pimpa59', '_blank')}}>
                    Classificação e Jogos
                </Button>
            </div>
            <div className='content-home-post'>
                <List
                    itemLayout="horizontal"
                    dataSource={data}
                    renderItem={(item, index) => (
                        <List.Item
                            actions={item.link ? [

                                <Button type="primary" htmlType="submit" className="invoice-form-button" onClick={() => window.open(item.link)}>
                                    Baixar
                                </Button>

                            ] : [<span> </span>]}>
                            <List.Item.Meta
                                avatar={item.type === "1" ? <NotificationOutlined /> : (item.type === "3" ? <DollarOutlined /> : <SyncOutlined />)}
                                title={item.title}
                                description={
                                    <Typography.Text>
                                        {item.description.split('\n').map((line, index) => (
                                            <span key={index}>
                                                {line}
                                                <br />
                                            </span>
                                        ))}
                                    </Typography.Text>
                                }
                            />
                        </List.Item>
                    )}
                />

            </div>
        </Main>
    )
}