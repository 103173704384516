/*import { Navigate } from "react-router-dom"
import { useIsAuth } from "../hooks/useAuth"

export function RequireAuth({ children }:{children:JSX.Element}){
    if(!useIsAuth()){
        return <Navigate to="/login" state={{ from: '/' }} replace />
    }
    return children

}
*/

import { ReactNode } from 'react'
import { Navigate } from 'react-router-dom'
import { useIsAuth } from "../hooks/useAuth"

interface RequireAuthProps {
  children: ReactNode
}

export function RequireAuth({ children }: RequireAuthProps) {
  const { isAuthenticated, loading, error } = useIsAuth()

  if (loading) {
    return <div>Loading...</div>
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: '/' }} replace />
  }

  if (error) {
    return <div>Error: {error}</div>
  }

  return <>{children}</>
}
