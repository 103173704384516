import '../styles/password.scss'
import { Main } from "../components/layout/Main"
import {BreadcrumbType} from "../types/types"
import { Button, Form, Input, Space, Typography } from 'antd'
import {  LockOutlined } from '@ant-design/icons'
import api from '../services/api'
import { toast } from 'react-toastify'

const items: BreadcrumbType[] = [
    {
        title: 'Home'
    },
    {
        title: 'Usuário'
    },
    {
        title: 'Alterar Senha',
    },
]

type FormValues = {
    password: string
    password_confirmed: string
}

export function Password() {
    const onFinish = async (values: FormValues) => {
        await api.post('user/password/set', new URLSearchParams({
            password: values.password,
            password_confirmed: values.password_confirmed
        }))
            .then((response) => {
                if (response.data.msg) {
                    toast.success(response.data.msg, { autoClose: 2000 })
                } else {
                    toast.error(response.data)
                }
            })
            .catch((e) => {
                if (e.response) {
                    // Is this the correct way?
                    toast.error(e.response?.data.error)
                }

            })
    }
    return (
        <Main itemBreadcrumb={items}>
            <Space direction="vertical" >
                <Typography.Title level={4}>
                    Alterar Senha
                </Typography.Title>
                <Form
                    name="normal_login"
                    className="login-form"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Digite uma senha!',
                            },
                        ]}
                    >
                        <Input
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            placeholder="Digite uma nova senha"
                        />
                    </Form.Item>
                    <Form.Item
                        name="password_confirmed"
                        rules={[
                            {
                                required: true,
                                message: 'Confirme sua senha!',
                            },
                        ]}
                    >
                        <Input
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            placeholder="Confirme sua nova senha"
                        />
                    </Form.Item>
                    <Form.Item className="login-submit">
                        <Button type="primary" htmlType="submit" className="login-form-button">
                            Salvar
                        </Button>
                    </Form.Item>
                </Form>
            </Space>
        </Main>
    )
}