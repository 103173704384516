import type { MenuProps } from 'antd'
import { LogoutOutlined, HomeOutlined, CalendarOutlined, UserOutlined, SkinOutlined, TrophyOutlined  } from '@ant-design/icons'
import { Menu, Layout } from 'antd'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import MenuItem from 'antd/es/menu/MenuItem'


const { Sider } = Layout

type MenuItem = Required<MenuProps>['items'][number]

function getItem(label: React.ReactNode, key: React.Key, icon?: React.ReactNode, children?: MenuItem[]): MenuItem {
    return {
        key,
        icon,
        children,
        label,
    } as MenuItem
}


const items: MenuItem[] = [
    getItem('Home', '/', <HomeOutlined />),
    getItem('Mensalidades', '/invoice', <CalendarOutlined />),
    getItem('Usuário', '/user', <UserOutlined />, [
        getItem('Perfil', '/user/profile'),
        getItem('Senha', '/user/password'),
    ]),
    getItem('Meu Time', '/team', <SkinOutlined />),
    getItem('Confirmação', '/party', <TrophyOutlined  />),
    getItem('Sair', '/logout', <LogoutOutlined />),
]

type MenuCustomProps = {
    setIsOpen: (value: boolean) => void
    isOpen: boolean
}



export function MenuCustom(props: MenuCustomProps) {
    const history = useNavigate()
    const location = useLocation()
    const [collapsed, setCollapsed] = useState(false)
    const [openKeys, setOpenKeys] = useState<string[]>([])
    const [selectedKeys, setSelectedKeys] = useState('/logout')

    const onClick: MenuProps['onClick'] = (e) => {
        if (props.isOpen) {
            props.setIsOpen(!props.isOpen)
        }
        setSelectedKeys(e.key)
        if (e.key === '/logout') {
            onLogoutClick()
        } else {
            history(e.key)
        }
    }

    const onLogoutClick = () => {
        localStorage.removeItem('token');
        history('/login')
    }

    const onOpenChange = (keys: string[]) => {
        setOpenKeys(keys)
        localStorage.setItem('isOpenKeys', JSON.stringify(keys))
    }


    const onCollapse = (value: any) => {
        if (props.isOpen) {
            props.setIsOpen(!props.isOpen)
        } else {
            setCollapsed(value)
        }
    }

    useEffect(() => {
        setSelectedKeys(location.pathname)
        const storedMenuState = localStorage.getItem('isOpenKeys')
        if (storedMenuState !== null) {
            setOpenKeys(JSON.parse(storedMenuState))
        }
    }, [location.pathname])

    return (
        <Sider className={`siderbar-menu ${props.isOpen ? 'active' : ''}`} collapsible collapsed={collapsed} onCollapse={(value) => onCollapse(value)}>
            <Menu onClick={onClick} theme="dark" mode="inline" items={items} selectedKeys={[selectedKeys]} openKeys={openKeys} onOpenChange={onOpenChange} />
        </Sider>
    )

}