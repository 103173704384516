import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Home } from './pages/Home';
import { Invoice } from './pages/Invoice';
import { Profile } from './pages/Profile';
import { Password } from './pages/Password';
import { Login } from './pages/Login';
import { RequireAuth } from './context/RequireAuth';
import { Notfound } from './pages/Notfound';
import { Team } from './pages/Team';
import { Party } from './pages/Party';

function App() {

  return (
    <BrowserRouter>
      <div>
        <ToastContainer />
        <Routes>
          <Route path="*" element={<Notfound />} />
          <Route path="/login" element={<Login />} />
          <Route path="/invoice" element={
            <RequireAuth>
              <Invoice />
            </RequireAuth>
          } />
          <Route path="/team" element={
            <RequireAuth>
              <Team />
            </RequireAuth>
          } />
          <Route path="/party" element={
            <RequireAuth>
              <Party />
            </RequireAuth>
          } />
          <Route path="/user/profile" element={
            <RequireAuth>
              <Profile />
            </RequireAuth>
          } />
          <Route path="/user/password" element={
            <RequireAuth>
              <Password />
            </RequireAuth>
          } />
          <Route path="/" element={
            <RequireAuth>
              <Home />
            </RequireAuth>
          } />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
