import '../styles/notfound.scss'
import { Button } from "antd"
import { MainLogout } from "../components/layout/MainLogout"
import { useNavigate } from "react-router-dom"


export function Notfound() {
    const history = useNavigate()
    const currentUrl = window.location.href
    return (
        <MainLogout>
            <h1>Página não encontrada</h1>
            <h3>{currentUrl}</h3>
            <Button type="primary" onClick={() => { history('/') }}>Voltar</Button>
        </MainLogout>
    )
}