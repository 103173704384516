import { Button, Input, Modal, Space, Typography } from "antd"
import { InvoiceType } from "../../types/types"
import dayjs from "dayjs"
import utc  from 'dayjs/plugin/utc'

type ModalPaymentProps = {
    handleOk: () => void
    setIsModalOpen: (value: boolean) => void
    isModalOpen: boolean
    invoice?: InvoiceType
}

export function ModalPayment(props: ModalPaymentProps) {
    dayjs.extend(utc)
    async function handlePrint() {
        window.print()
    }

    return (
        <Modal title="Comprovante de Pagamento" open={props.isModalOpen} onCancel={props.handleOk} footer={null}>
            <div className='payment-detail'>
                <Space direction="vertical" >
                    <Typography.Title level={5}>
                        Associado: {props.invoice?.user?.name}<br />
                        Descrição: {props.invoice?.description}<br />
                        Data Vencimento: {dayjs(props.invoice?.due_at, "YYYY-MM-DD HH:mm:ss").format('DD/MM/YYYY')}<br />
                        Data de Pagamento: {dayjs(props.invoice?.paid_at+"Z", "YYYY-MM-DD HH:mm:ssZ").utc().local().format('DD/MM/YYYY HH:mm:ss')}<br />
                        Código de Processamento: {props.invoice?.pagarme_id}
                    </Typography.Title>
                    <Button type="primary" onClick={handlePrint}>Imprimir</Button>
                </Space>
            </div>
        </Modal>
    )
}