import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import 'dayjs/locale/pt-br'
import { DatePicker } from 'antd'
import { DatePickerProps } from 'antd/lib/date-picker'


dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)
dayjs.locale('pt-br')

export function InputDate({...props }: DatePickerProps ) {
    
    return (
        <DatePicker
            {...props}
            format="DD/MM/YYYY"
        />
    )
}
