import { MainLogout } from "../components/layout/MainLogout"
import { Button, Form, Input } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import Link from "antd/es/typography/Link"
import api from '../services/api'
import { Navigate, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import logoIMG from '../assets/logo192.png'
import { useIsAuth } from "../hooks/useAuth"
import { MaskedInput } from 'antd-mask-input'

type FormValues = {
    document: string
    password: string
    remember: boolean
}

export function Login() {
    const history = useNavigate()

    const onFinish = async (values: FormValues) => {
        await api.post('login', new URLSearchParams({
            document: values.document,
            password: values.password
        }))
            .then((response) => {
                if (response.data.Authorization) {
                    localStorage.setItem("token", response.data.Authorization)
                    history('/')
                    toast.success('Login Efetuado com sucesso!', { autoClose: 2000 })
                } else {
                    toast.error(response.data)
                }
            })
            .catch((e) => {
                if (e.response) {
                    // Is this the correct way?
                    toast.error(e.response?.data.error)
                }
            })
    }
    const { isAuthenticated} = useIsAuth();
    if (isAuthenticated) {
        return <Navigate to="/" state={{ from: '/login' }} replace />;
    }
    return (
        <MainLogout>
            <div className="logo">
                <img src={logoIMG} width="150px" alt="Logo" />
            </div>
            <div className="form-login">
                <Form
                    name="normal_login"
                    className="login-form"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="document"
                        rules={[
                            {
                                required: true,
                                message: 'Digite seu CPF',
                                pattern: /^\d{3}\.\d{3}\.\d{3}-\d{2}$/,
                            },
                        ]}
                    >
                        <MaskedInput
                            mask='000.000.000-00' 
                            prefix={<UserOutlined className="site-form-item-icon" />}
                            placeholder="CPF"
                            maskOptions={{lazy:true}}
                        />

                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Digite sua Senha!',

                            },
                        ]}
                    >
                        <Input
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            placeholder="Senha"
                        />
                    </Form.Item>
                    <Form.Item className="login-submit">
                        <Button type="primary" htmlType="submit" className="login-form-button">
                            Enviar
                        </Button>
                    </Form.Item>
                    <Form.Item className="login-options">
                        <Link className="login-form-forgot" href="#" onClick={()=> toast.success('Sua senha é seu CPF, para o primeiro acesso copie o Login para o Campo senha', { autoClose: 15000 })}>
                            Esqueci Minha Senha
                        </Link>
                    </Form.Item>
                </Form>
            </div>
        </MainLogout>
    )
}