import '../../styles/global.scss'
import '../../styles/main.scss'

import logoIMG from '../../assets/logo48.png'

import React, { ReactNode, useState } from 'react'
import { blue } from '@ant-design/colors'
import { Avatar, Breadcrumb, Layout, theme } from 'antd'
import { MenuCustom } from './Menu'
import { Link } from 'react-router-dom'
import {BreadcrumbType} from '../../types/types'


const { Header, Content, Footer } = Layout



type MainCustomProps = {
    itemBreadcrumb: BreadcrumbType[]
    children?: ReactNode
}

export function Main(props: MainCustomProps) {
    const [isOpen, setIsOpen] = useState(false)
    
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken()

    function onClickHamburger(){
        setIsOpen(!isOpen)
    }

    

    return (

        <Layout style={{ minHeight: '100dvh' }}>
            <MenuCustom isOpen={isOpen} setIsOpen={setIsOpen} />
            <Layout>
                <Header style={{ padding: 0, background: colorBgContainer, display: 'flex', justifyContent: 'space-between', margin:'5px' }}>
                    <div className='menu-toggle' onClick={onClickHamburger}>
                        <svg fill={blue.primary} viewBox="0 0 100 80" width="48" height="48">
                            <rect width="100" height="20" rx="10"></rect>
                            <rect y="30" width="100" height="20" rx="10"></rect>
                            <rect y="60" width="100" height="20" rx="10"></rect>
                        </svg>
                    </div>
                    <Avatar size="large" src={logoIMG} alt="User Avatar" style={{ height: '64px', width: '64px' }} />
                </Header>
                <Content style={{ margin: '0 16px' }}>
                    <Breadcrumb style={{ margin: '16px 0' }}
                        items={props.itemBreadcrumb} />
                    <div className='content-children'
                        style={{
                            padding: 16,
                            minHeight: 360,
                            background: colorBgContainer,
                            borderRadius: borderRadiusLG,
                        }}
                    >
                        {props.children}
                    </div>
                </Content>
                <Footer style={{ textAlign: 'center', paddingBottom: '5px' }}>Associação Amigos da Pimpa ©2024 Created by Rodrigo Balaba</Footer>
            </Layout>
        </Layout>
    )
}
