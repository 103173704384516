import axios from 'axios'

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASEURL
})

api.interceptors.request.use(async config => {
  const token = localStorage.getItem("token")

  let configWithHeaders = {}

  if (token) {
    configWithHeaders = {
      headers: {
        Authorization: token,
      },
    }
  }

  return { ...config, ...configWithHeaders }
})

api.interceptors.response.use(
  response => {
    return response
  },

  error => {
    if (error.response && error.response.status === 401) {
      localStorage.clear()
      window.location.href = '/login'
    }
    return Promise.reject(error)
  }
)

export default api