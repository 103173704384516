import { Button, Form, Input, Space, Typography } from "antd"
import { Main } from "../components/layout/Main"
import { BreadcrumbType } from "../types/types"
import { UserOutlined, UserSwitchOutlined, PhoneOutlined, FileDoneOutlined } from '@ant-design/icons'
import { useEffect } from "react"
import api from "../services/api"
import { InputDate } from "../components/objects/InputDate"
import dayjs, { Dayjs } from "dayjs"
import { toast } from "react-toastify"

const items: BreadcrumbType[] = [
    {
        title: 'Home'
    },
    {
        title: 'Usuário'
    },
    {
        title: 'Perfil',
    },
]

type FormValues = {
    name: string
    nickname: string
    birthdate: Dayjs
    phone: string
    document: string
}

export function Profile() {
    const [form] = Form.useForm()
    const onFinish = async (values: FormValues) => {
        await api.post('user/set', new URLSearchParams({
            nickname: values.nickname,
            birthdate: values.birthdate.format('YYYY-MM-DD'),
            document: values.document,
        }))
            .then((response) => {
                if (response.data.msg) {
                    toast.success(response.data.msg, { autoClose: 2000 })
                    try {
                        api.get('user/get')
                            .then((response) => {
                                form.setFieldsValue({
                                    name: response.data.user.name,
                                    nickname: response.data.user.nickname,
                                    birthdate: dayjs(response.data.user.birthdate, "YYYY-MM-DD HH:mm:ss"),
                                    phone: response.data.user.phone,
                                    document: response.data.user.document,
                                })
                            })
                    } catch (error) {
                        console.error('Erro ao carregar dados do usuário:', error)
                    }
                } else {
                    toast.error(response.data)
                }
            })
            .catch((e) => {
                if (e.response) {
                    // Is this the correct way?
                    toast.error(e.response?.data.error)
                }

            })
    }

    useEffect(() => {
        async function loadData() {
            try {
                const response = await api.get('user/get')
                form.setFieldsValue({
                    name: response.data.user.name,
                    nickname: response.data.user.nickname,
                    birthdate: dayjs(response.data.user.birthdate, "YYYY-MM-DD HH:mm:ss"),
                    phone: response.data.user.phone,
                    document: response.data.user.document,
                })
            } catch (error) {
                console.error('Erro ao carregar dados do usuário:', error)
            }
        }
        loadData()
    }, [form])
    return (
        <Main itemBreadcrumb={items}>
            <Space direction="vertical" >
                <Typography.Title level={4}>
                    Meus Dados
                </Typography.Title>
                <Form
                    form={form}
                    name="profile"
                    className="login-form"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="name"

                        rules={[
                            {
                                required: true,
                                message: 'Digite seu Nome igual ao documento!',
                            },
                        ]}
                    >
                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Nome Similar ao RG" disabled={true} />
                    </Form.Item>
                    <Form.Item
                        name="nickname"
                        rules={[
                            {
                                required: true,
                                message: 'Digite seu Apelido usado na Pimpa!',
                            },
                        ]}
                    >
                        <Input prefix={<UserSwitchOutlined className="site-form-item-icon" />} placeholder="Apelido na Pimpa" />
                    </Form.Item>
                    <Form.Item
                        name="birthdate"
                        rules={[
                            {
                                required: true,
                                message: 'Digite sua Data de Nascimento!',
                            },
                        ]}
                    >
                        <InputDate placeholder="Data de Nascimento" />
                    </Form.Item>
                    <Form.Item
                        name="phone"
                        rules={[
                            {
                                required: true,
                                message: 'Digite seu Telefone com Whatsapp!',
                            },
                        ]}
                    >
                        <Input prefix={<PhoneOutlined className="site-form-item-icon" />} placeholder="Telefone com Whatsapp" disabled={true} />
                    </Form.Item>
                    <Form.Item
                        name="document"
                        rules={[
                            {
                                required: true,
                                message: 'Digite seu CPF!',
                            },
                        ]}
                    >
                        <Input prefix={<FileDoneOutlined className="site-form-item-icon" />} placeholder="CPF" disabled={true} />
                    </Form.Item>
                    <Form.Item className="login-submit">
                        <Button type="primary" htmlType="submit" className="login-form-button" >
                            Salvar
                        </Button>
                    </Form.Item>
                </Form>
            </Space>
        </Main>
    )
}
//onMouseOut={() => console.log(form.getFieldValue('birthdate'))}